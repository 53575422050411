
import React, {useState} from "react"
import Layout from "../../components/layout"

import WebappForm from "../../components/webapps/form"

const formatTools = require("../../../lib/formatTools")
const webappsAPI = require("../../../lib/requestWebapps");


const ENTITYID="onsorganization"


const searchFields = [
	{"label":"Name", "dbfield": "onsorganization_name", "type": "text", "filtertype": "text"},
	{"label":"Type/Notes", "dbfield": "onsorganization_notes", "type": "text", "filtertype": "text"},
];


const formFields = [
	{"label":"Official Name", "field": "onsorganization_name", "value": "", "input": "textbox", "mode": "unique"},
	{"label":"Type/Notes", "field": "onsorganization_notes", "value": "", "input": "textbox", "mode": "normal"},
];


const OrganizationPage = ({location}) => {
	const [token, setToken] = useState("");
	const [pagetitle, setPagetitle] = useState("");
	const [documentid, setDocumentid] = useState(0);

	function customSubmit(entity, params, payloadlist, subformpayloadlist, token, callback)
	{
		var pkid = 0;
		//var customparam = {};
		if (params.hasOwnProperty("documentid")) {
			pkid = params.documentid;
		}
		if (pkid === 0) {
			params.onsorganization_created=formatTools.getDateStr();
		}

		//callback({"status":"Error", "message":JSON.stringify(params)}); return;

		webappsAPI.savePayload(entity, params, token, payloadlist, subformpayloadlist, callback);
	}


	function customEditFieldInfo(inputlist, idx)
	{
		var tmpfield = {};
		if (documentid > 0) {
			tmpfield = JSON.parse(JSON.stringify(inputlist[idx]));
			tmpfield.mode = "readonly";
			return tmpfield;
		}
		return inputlist[idx];
	}



	function customSetFormData(newformdata)
	{
		var newdocid = 0;
		if (newformdata.hasOwnProperty(ENTITYID+"_id")) {
			if (newformdata[ENTITYID+"_id"]) {
				newdocid = newformdata[ENTITYID+"_id"];
			}
		}

		setDocumentid(newdocid);
	}

	return <Layout location={location}
			newPageTitle={(newtitle)=>{setPagetitle(newtitle)}}
			newtokenHandler={(newtoken)=>{setToken(newtoken)}}
			privatePage={true} usegatedcontent={true}
		>
			<WebappForm
				location={location}
				pagetitle={pagetitle}
				allowadd={true}
				allowdelete={false}
				entity={ENTITYID}
				mobilerowtitlefield={["onsorganization_name"]}
				searchFields={searchFields}
				editFields={[formFields]}

				customSubmit={customSubmit}
				customEditFieldInfo={customEditFieldInfo}
				customSetFormData={customSetFormData}

				token={token} />
		</Layout>

}


export default OrganizationPage;
